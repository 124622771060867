<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <!--관련법규-->
          <c-select
            codeGroupCd="RELATED_LAWS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="relatedLawsCd"
            label="LBL0010245"
            v-model="searchParam.relatedLawsCd"
          ></c-select>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-text
            name="search"
            placeholder="인허가 종류명"
            label="인허가 종류명"
            v-model="searchParam.limLicenseKindName"
          ></c-text>
        </div> -->
      </template>
    </c-search-box>
    <!--인허가 현황 목록-->
    <c-table
      ref="table"
      title="LBL0010246"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :merge="grid.merge"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <div>
          <q-btn-group outline>
            <!--직접등록-->
            <c-btn label="LBL0010247" v-if="editable" icon="add" @btnClicked="licensePop" />
            <!--검색-->
            <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
          </q-btn-group>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: "license-status",
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            //사업장
            label: 'LBLPLANT',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'revisionNum',
            field: 'revisionNum',
            label: 'Rev.',
            align: 'center',
            style: 'width:50px',
            sortable: true,
          },
          {
            name: 'licenseNo',
            field: 'licenseNo',
            //인허가번호
            label: 'LBL0010248',
            align: 'center',
            sortable: true,
          },
          {
            name: 'relatedLawsName',
            field: 'relatedLawsName',
            //관련법규
            label: 'LBL0010245',
            align: 'center',
            sortable: true,
          },
          {
            name: 'limLicenseKindName',
            field: 'limLicenseKindName',
            //인허가종류
            label: 'LBL0010249',
            align: 'left',
            type: 'link',
            sortable: true,
          },
          {
            name: 'licenseStartDate',
            field: 'licenseStartDate',
            //인허가시작일
            label: 'LBL0010250',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseEndDate',
            field: 'licenseEndDate',
            //인허가종료일
            label: 'LBL0010251',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'relatedDeptName',
            field: 'relatedDeptName',
            //관련부서
            label: 'LBL0010252',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'licenseCreateTypeName',
            field: 'licenseCreateTypeName',
            //인허가 등록 구분
            label: 'LBL0010253',
            align: 'center',
            style: 'width:180px',
            sortable: true,
          },
          {
            name: 'sopMocId',
            field: 'sopMocId',
            //관련MOC
            label: 'LBL0010254',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        limLicenseKindName: '',
        relatedLawsCd: null,
      },
      editable: true,
      searchUrl: "",
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      isResult: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.sop.lim.lincense.card.list.url;
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.licensePop(row);
    },
    licensePop(result) {
      this.popupOptions.target = () => import(`${"./licenseStatusDetail.vue"}`);
      this.popupOptions.title = 'LBL0010255'; //인허가 상세
      this.popupOptions.param = {
        limLicenseId: result ? result.limLicenseId : '',
      };
      this.popupOptions.width = '100%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  },
};
</script>
